<template>
    <!-- index.vue -->

    <!--        :class="{
              'app-admin-wrap-layout-1 sidebar-mini': getThemeMode.verticalSidebarMini,
              'sidebar-close': !getThemeMode.verticalSidebarDrawer,
          }"-->
    <v-app
        :style="{
            background: getCompany.color_background || $vuetify.theme.themes[theme].background,
            color: $vuetify.theme.themes[theme].color,
        }"
    >
        <app-bar
            class=""
            :side-bar-drawer="false"
        >
            <!-- -->
        </app-bar>
        <!-- <sub-header>

    </sub-header> -->
        <!-- Sizes your content based upon application components -->

        <base-view />

        <c-snackbar />
        <loading />
    </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
    components: {
        BaseView: () => import("./ViewHome"),
        AppBar: () => import("./AppBar"),
        // SubHeader: () => import("./SubHeader"),
        CSnackbar: () => import('@/components/common/CSnackbar'),
        Loading: () => import('@/components/common/Loading')
    },

    computed: {
        ...mapGetters(["getThemeMode", "getCompany"]),

        theme() {
            return this.$vuetify.theme.dark ? "dark" : "light";
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
